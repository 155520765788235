export function useTextUtils() {

    async function copyToClipboard(text: string) {
        if (import.meta.client) {
            await navigator.clipboard?.writeText?.(text);
        }
    }

    return {
        copyToClipboard
    };
}
