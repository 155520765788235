<template>
    <v-select
        v-if="user?.memberships && user?.currentMembership"
        :model-value="user.currentMembership.uid"
        label="Select Organization"
        class="pa-0 ma-0 cursor-pointer"
        :items="user.memberships"
        item-title="entity"
        item-value="uid"
        variant="solo"
        origin="auto"
        location="bottom start"
        aria-label="Select Organization"
        :disabled="isLoading"
        @update:model-value="onChange($event)"
        hide-details
        single-line
        flat
    >
        <template #selection="{ item }">
            <div
                class="d-flex cursor-pointer align-center"
            >
                <div
                    v-if="item.raw.entity?.brand?.primaryLogo?.url"
                    class="flex-shrink-1 flex-grow-0"
                >
                    <v-avatar
                        size="32"
                    >
                        <v-img
                            width="auto"
                            height="auto"
                            :src="item.raw.entity.brand.primaryLogo.url"
                            :aspect-ratio="item.raw.entity.brand.primaryLogo.aspectRatio"
                        />
                    </v-avatar>
                </div>
                <div
                    class="pl-4 flex-shrink-1 flex-grow-0 text-truncate"
                >
                    {{ item.raw.entity?.name || 'Selected Organization' }}
                </div>
            </div>
        </template>
        <template #item="{ item, props, index }">
            <v-list-item
                v-bind="props"
                title=""
            >
                <template #default>
                    <div
                        class="d-flex align-center cursor-pointer"
                    >
                        <div
                            class="flex-shrink-1 flex-grow-0"
                        >
                            <v-avatar
                                size="32"
                            >
                                <v-img
                                    v-if="item.raw.entity?.brand?.primaryLogo?.url"
                                    width="auto"
                                    height="auto"
                                    :src="item.raw.entity.brand.primaryLogo.url"
                                    :aspect-ratio="item.raw.entity.brand.primaryLogo.aspectRatio"
                                />
                            </v-avatar>
                        </div>
                        <div
                            class="pl-4 flex-shrink-1 flex-grow-0 text-truncate"
                        >
                            {{ item.raw.entity?.name || `Organization ${index + 1}` }}
                        </div>
                    </div>
                </template>
            </v-list-item>
        </template>
    </v-select>
</template>

<script lang="ts" setup>
    import { useUser, useGlobalAlert } from '~/composables';
    import { isError as lo_isError } from 'es-toolkit';
    import { mdiAccountGroup } from '@mdi/js';

    const {
        userObj: user,
        setMembershipUid
    } = useUser({ siteContext: 'menu' });
    
    const router = useRouter();
    const createGlobalAlert = useGlobalAlert();
    const {
        isLoading,
        start: loaderStart,
        finish: loaderStop
    } = useLoadingIndicator();

    async function onChange(uid: string): Promise<void> {
        if (uid) {
            loaderStart();
            const response = await setMembershipUid(uid);

            if (lo_isError(response)) {
                loaderStop();
                createGlobalAlert({
                    text: 'Unable to switch memberships. Please reload page and try again.',
                    show: true,
                    type: 'error'
                });

                return;
            }

            setTimeout(() => {
                router.go(0);
            }, 800);
        }
    }
</script>

<style lang="scss" scoped></style>
