<template>
    <div
        v-if="isLoggedIn && userObj"
    >
        <div>
            <UserAvatar
                :title="userObj.email"
                :size="32"
                :avatar-url="userObj.avatarUrl"
                :name="[userObj.firstName, userObj.lastName]"
                :label="userObj.label"
                @dblclick="onCopy(userObj?.email ?? '')"
                class="mr-3"
            ></UserAvatar>
            <span
                class="ms-1 flex-grow-1"
                :title="userObj.email"
            >
                    {{ `${userObj.label}` }}
            </span>
        </div>
    
        <MembershipSelectorInput
            v-if="userObj?.memberships?.length"
        ></MembershipSelectorInput>
    </div>
</template>

<script lang="ts" setup>
    import UserAvatar from '~/components/user/UserAvatar/UserAvatar.vue';
    import MembershipSelectorInput from '~/components/controls/MembershipSelectorInput/MembershipSelectorInput.vue';
    import {
        useTextUtils,
        useUserLite
    } from '~/composables';

    const {
        userObj,
        isLoggedIn
    } = useUserLite();

    function onCopy(text: string): void {
        useTextUtils().copyToClipboard(text);
    }
</script>

<style lang="scss"></style>
